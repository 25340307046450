export const Headers = [
  {
    key: '1',
    header: 'Name',
  },
  {
    key: '2',
    header: 'Email',
  },
  {
    key: '3',
    header: 'Org Unit',
  },
  {
    key: '4',
    header: 'Admin',
  },
];
