import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import authService from '../../api/auth/auth.service';
import PropTypes from 'prop-types';

export const ProtectedRoute = () => {
  const currentUser = authService.getCurrentUser();
  if (!currentUser) {
    return (
      <Navigate to={'/login?callback_url=' + window.location.href} replace />
    );
  }

  return <Outlet />;
};
