import React from 'react';

export const UserMatriceCard = ({ value, header, unit }) => {
  return (
    <div className='bg-[#D9D9D9] w-full h-[122px] p-4'>
      <div className='flex flex-grow flex-col justify-between h-full'>
        <div className=''>{header}</div>
        <div className=''>
          <span className='text-4xl font-semibold'>{value}</span>
          {unit}
        </div>
      </div>
    </div>
  );
};
