import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  DataTableSkeleton,
  DataTable,
} from '@carbon/react';
// import {second} from 'carbon-components-react'
import { useLocation, useNavigate } from 'react-router-dom';
import usersService, { IOrgUser } from '../api/users/users.service';
import { UserMatriceCard } from '../Components/UserMatriceCard/UserMatriceCard';
import { UserMatrices } from '../Components/UserMatrices/UserMatrices';
import { Headers } from '../Config/UsersHeaders/UsersHeaders';
import {
  Button,
  ComboBox,
  Form,
  Pagination,
  Search,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
  Toggle,
} from 'carbon-components-react';
import { log } from 'console';
import { Filter } from '../Components/Filter/Filter';
import { IPaginateOptions, IPaginationState } from '../api/types';

const request_options: IPaginateOptions = {
  limit: 10,
  search: '',
  sort: {},
  page: 1,
};
interface IState extends IPaginationState<IOrgUser> {
  isLoadingData: boolean;
  page: number;
  paginate_options: IPaginateOptions;
}

export const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState<IState>({
    isLoadingData: true,
    page: 1,
    paginate_options: request_options,
    docs: [],
  });

  const [matrices, setmatrices] = useState({
    noOfOrgunits: 0,
    noOfUser: 0,
    noOfDomain: 0,
    noOfAdmin: 0,
  });

  const [userData, setUserData] = useState<any[]>([]);

  const handleManagePathRedirect = (value) => {
    return navigate(value);
  };

  const getMatrices = async () => {
    try {
      const { data } = await usersService.getMatrices();
      setmatrices(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleTableChange = async (pagination: {
    page: number;
    pageSize?: number;
  }) => {
    getUsers({
      limit: pagination.pageSize,
      page: pagination.page,
    });
  };

  const getUsers = async (options: IPaginateOptions) => {
    if (options.search != state.paginate_options.search) {
      options.page = 1;
    }
    setState((preState) => {
      preState.isLoadingData = true;
      preState.paginate_options = options;
      return { ...preState };
    });
    try {
      const { data } = await usersService.getUsers(options.filters, [], {
        limit: options.limit,
        page: options.page,
        sort: options.sort,
        search: options.search,
        populate: {},
      });

      setState({
        isLoadingData: false,
        docs: data.docs,
        paginate_options: state.paginate_options,
        nextPage: data.nextPage,
        prevPage: data.prevPage,
        page: data.page,
        limit: data.limit,
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        offset: data.offset,
        totalPages: data.totalPages,
        totalDocs: data.totalDocs,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getMatrices();
    getUsers(request_options);
  }, [location]);

  return (
    <div>
      <div>
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => {
              handleManagePathRedirect('/');
            }}
          >
            Home
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className='mt-1'>
        <h1 className='font-bold'>Lorem ipsum</h1>
      </div>
      <div className='mt-1'>
        <p className=''>Description about the dashboard</p>
      </div>
      <UserMatrices matrices={matrices} />
      <div className='mt-4 flex gap-x-4'>
        <Filter />
        <div className='bg-[#D9D9D9] w-5/6 p-4 h-full flex flex-col justify-between'>
          <DataTable rows={state.docs} headers={Headers}>
            {({ getTableProps, getHeaderProps, headers }) => (
              <TableContainer>
                <Table {...getTableProps()}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header, index) => (
                        <TableHeader
                          key={index}
                          className='single-line-text'
                          {...getHeaderProps({
                            header,
                            isSortable: Boolean(header.sort),
                          })}
                          isSortHeader={Boolean(header.sort)}
                          // sortDirection={sortDirection}
                        >
                          {header.header}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.docs.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.name.fullName}</TableCell>
                        <TableCell>{row.primaryEmail}</TableCell>
                        <TableCell>{row.orgUnitPath}</TableCell>
                        <TableCell>
                          {row.isAdmin && (
                            <Tag type='green' title='true'>
                              True
                            </Tag>
                          )}
                          {!row.isAdmin && (
                            <Tag type='red' title='false'>
                              False
                            </Tag>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </DataTable>
          <Pagination
            backwardText='Previous page'
            forwardText='Next page'
            itemsPerPageText='Items per page:'
            page={1}
            pageNumberText='Page Number'
            pageSize={10}
            pageSizes={[]}
            pageSizeInputDisabled={true}
            totalItems={state.totalDocs}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};
