import { Diagram } from '@carbon/icons-react';

export const Items = [
  {
    title: 'Dashboard',
    link: '/',
    submenu: [],
    icon: Diagram,
  },
  {
    title: 'Drive',
    link: '/drive-files',
    submenu: [],
    icon: Diagram,
  },
];
