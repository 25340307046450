import axios from 'axios';
import {
  getCookie,
  removeCookie,
  setCookie,
} from '../../utils/cookies/cookies';
import { DateUtils } from '../../utils/date/date';
import { AUTH_URL } from '../baseurl';

const API_URL = AUTH_URL + '/auth/google';

class AuthService {
  login(tokenId: string) {
    setCookie('authorization', tokenId, DateUtils.getFutureDate(1));
    return true;
  }

  logout() {
    removeCookie('authorization');
  }

  getCurrentUser() {
    const token = getCookie('authorization');
    return token;
  }

  getLoginUrl(url: string) {
    return axios.get(API_URL + '/login_url?redirect_url=' + url);
  }
}
export default new AuthService();
