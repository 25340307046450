import React from 'react';
import { UserMatriceCard } from '../UserMatriceCard/UserMatriceCard'

export const UserMatrices = ({ matrices }) => {
  return (
    <div className='flex justify-around w-full gap-x-10 mt-8'>
      <UserMatriceCard
        value={matrices.noOfOrgunits}
        header={'No of Organization Unit'}
        unit={'units'}
      />
      <UserMatriceCard
        value={matrices.noOfUser}
        header={'No of users'}
        unit={'users'}
      />
      <UserMatriceCard
        value={matrices.noOfAdmin}
        header={'No of admin users'}
        unit={'users'}
      />
      <UserMatriceCard
        value={matrices.noOfDomain}
        header={'No of domain in Organization'}
        unit={'domain'}
      />
    </div>
  );
};
