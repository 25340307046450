import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  HeaderContainer,
  Theme,
  Header,
  SkipToContent,
  HeaderMenuButton,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SideNavItems,
  SideNavLink,
  SideNav,
  SideNavMenu,
  SideNavMenuItem,
} from '@carbon/react';

import Logo from '../logo.png';

import { Items } from '../Config/SidebarItems';
import authService from '../api/auth/auth.service';
import { ProtectedRoute } from './ProtectedRoute/PrivateRoute';
import { Logout, Notification, User } from '@carbon/icons-react';
import { DriveFiles } from '../Pages/DriveFiles';
import { Users } from '../Pages/Users';

export const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    // loggedOutUser();
    window.location.href = '/login';
  };
  const handleManagePathRedirect = (value) => {
    return navigate(value);
  };

  return (
    <>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <>
            <Theme theme='g100'>
              <Header aria-label='IBM Platform Name'>
                <SkipToContent />
                <HeaderMenuButton
                  aria-label='Open menu'
                  onClick={onClickSideNavExpand}
                  isActive={isSideNavExpanded}
                />
                <HeaderName href='/' prefix=''>
                  <img src={Logo} alt='DAT' width='35px' />
                  <span style={{ marginLeft: '5px' }}>
                    Drive Analytics Tool
                  </span>
                </HeaderName>
                <HeaderGlobalBar>
                  <HeaderGlobalAction
                    aria-label='Notifications'
                    onClick={() => {}}
                  >
                    <Notification />
                  </HeaderGlobalAction>
                </HeaderGlobalBar>
              </Header>
            </Theme>
            <Theme theme='g10'>
              <SideNav
                isFixedNav
                expanded={true}
                aria-label='Side navigation'
                className='side-nav'
              >
                <SideNavItems>
                  {Items.map((menu, index) => {
                    if (menu.submenu.length === 0) {
                      return (
                        <SideNavLink
                          renderIcon={menu.icon}
                          key={index}
                          aria-current={
                            location.pathname === menu.link && 'page'
                          }
                          onClick={() => {
                            handleManagePathRedirect(menu.link);
                          }}
                        >
                          <span>{menu.title}</span>
                        </SideNavLink>
                      );
                    }
                    return (
                      <SideNavMenu
                        key={index}
                        title={menu.title}
                        renderIcon={menu.icon}
                      >
                        {menu.submenu.map((submenu, index) => {
                          return (
                            <SideNavMenuItem
                              aria-current={
                                location.pathname === submenu.link && 'page'
                              }
                              onClick={() => {
                                handleManagePathRedirect(menu.link);
                              }}
                              key={index}
                            >
                              {submenu.title}
                            </SideNavMenuItem>
                          );
                        })}
                      </SideNavMenu>
                    );
                  })}
                  D{' '}
                </SideNavItems>
                <SideNavItems>
                  <SideNavLink onClick={handleLogout}>
                    <Logout /> Logout
                  </SideNavLink>
                </SideNavItems>
              </SideNav>
            </Theme>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path='/' element={<Users />} />
                <Route path='/drive-files' element={<DriveFiles />} />
              </Route>
            </Routes>
          </>
        )}
      />
    </>
  );
};
