import Cookies from 'js-cookie';
export function getAllCookies() {
  return Cookies.get();
}
export function setCookie(
  key: string,
  value: string,
  expireTime: number | Date,
) {
  return Cookies.set(key, value, {
    domain: process.env.REACT_APP_DOMAIN,
    expires: expireTime,
  });
}
export function getCookie(key: string) {
  return Cookies.get(key);
}
export function removeCookie(key: string) {
  return Cookies.remove(key, {
    domain: process.env.REACT_APP_DOMAIN,
  });
}
