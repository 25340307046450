import {
  Button,
  ComboBox,
  Form,
  Search,
  Toggle,
} from 'carbon-components-react';
import React, { useState } from 'react';

export const Filter = () => {
  const [f_Email, setf_Email] = useState<string>(null);
  const [f_admin, setF_admin] = useState(false);
  const [f_active, setF_active] = useState(false);
  return (
    <div className='bg-[#D9D9D9] w-1/6 p-4'>
      <Form>
        <div className='flex flex-col gap-y-2'>
          <div className='flex flex-col gap-y-2'>
            <div className='text-opacity-60 text-[#000000]'>Email</div>
            <Search
              labelText='aa'
              id='email'
              placeholder='Search email'
              size='sm'
              onChange={(e) => {
                setf_Email(e.target.value);
              }}
            />
          </div>
          <div className='flex justify-between'>
            <div className='text-opacity-90 text-[#000000] flex pb-1 items-end'>
              Admin
            </div>
            <Toggle
              labelText=''
              toggled={f_admin}
              size='sm'
              id='admin'
              onToggle={(value) => {
                setF_admin(value);
              }}
            />
          </div>
          <div className='flex justify-between'>
            <div className='text-opacity-90 text-[#000000] flex pb-1 items-end'>
              Active
            </div>
            <Toggle
              labelText=''
              defaultToggled={false}
              size='sm'
              id='active'
              onToggle={(value) => {
                setF_active(value);
              }}
            />
          </div>
          <div className='flex flex-col gap-y-2 mt-2'>
            <div className='text-opacity-60 text-[#000000] flex pb-1 items-end'>
              Organization unit
            </div>
            <ComboBox
              size='sm'
              ariaLabel='ComboBox'
              id='carbon-combobox-example'
              items={['items', 'aa']}
              onChange={(e) => {
                console.log(e.selectedItem);
              }}
            />
          </div>
          <div className='flex flex-col gap-y-2 mt-2'>
            <div className='text-opacity-60 text-[#000000] flex pb-1 items-end'>
              Created date
            </div>
            <ComboBox
              size='sm'
              ariaLabel='ComboBox'
              id='carbon-combobox-example'
              items={['items', 'aa']}
              onChange={(e) => {
                console.log(e.selectedItem);
              }}
            />
          </div>
          <div className='flex justify-between'>
            <div className='text-opacity-90 text-[#000000] flex pb-1 items-end'>
              Have mail license
            </div>
            <Toggle
              labelText=''
              toggled={f_admin}
              size='sm'
              id='admin'
              onToggle={(value) => {
                setF_admin(value);
              }}
            />
          </div>
          <div className='flex flex-col gap-y-2 mt-2'>
            <div className='text-opacity-60 text-[#000000] flex pb-1 items-end'>
              File Size
            </div>
            <ComboBox
              size='sm'
              ariaLabel='ComboBox'
              id='carbon-combobox-example'
              items={['items', 'aa']}
              onChange={(e) => {
                console.log(e.selectedItem);
              }}
            />
          </div>
          <div className='flex justify-between mt-2'>
            <div className=''>
              <Button kind='primary' size='sm'>
                Apply
              </Button>
            </div>
            <div className=''>
              <Button kind='tertiary' size='sm'>
                Reset
              </Button>
            </div>
          </div>
        </div>

        {/* <Button
      type='submit'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      a
    </Button> */}
      </Form>
    </div>
  );
};
