import axios from 'axios';
import { getQueryUrl } from '../../utils/utils';
import authHeader from '../auth/auth.header';

import { API_URL } from '../baseurl';
import { IPaginateOptions, IPaginationResult } from '../types';

const URL = API_URL;

interface iUserEmail {
  address: string;
  primary: boolean;
}
interface iUserLanguage {
  languageCode: string;
  preference: string;
}
export interface IOrgUser {
  kind: string;
  id: string;
  etag: string;
  primaryEmail: string;
  name: {
    givenName: string;
    familyName: string;
    fullName: string;
  };
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  lastLoginTime: string;
  creationTime: string;
  agreedToTerms: boolean;
  suspended: boolean;
  archived: boolean;
  changePasswordAtNextLogin: boolean;
  ipWhitelisted: boolean;
  emails: iUserEmail[];
  languages: iUserLanguage[];
  customerId: string;
  orgUnitPath: string;
  isMailboxSetup: boolean;
  isEnrolledIn2Sv: boolean;
  isEnforcedIn2Sv: boolean;
  includeInGlobalAddressList: boolean;
}

class UsersService {
  getUsers(filter: object, project?: string[], options?: IPaginateOptions) {
    let url = URL + '/users' + '?' + getQueryUrl(filter, project, options);
    return axios.get<IPaginationResult<IOrgUser>>(url, {
      headers: authHeader(),
    });
  }
  getOrgunits() {
    return axios.get(URL + '/users/orgunits', {
      headers: authHeader(),
    });
  }
  getMatrices() {
    return axios.get(URL + '/users/matrices', {
      headers: authHeader(),
    });
  }
}
export default new UsersService();
