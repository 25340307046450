import { IPaginateOptions } from '../api/types';

export function getQueryUrl(
  filter: object,
  project?: string[],
  options?: IPaginateOptions
) {
  if (project) project = project.map((e) => e.trim()).filter((e) => e);
  const query: any = [];
  if (project && project.length) {
    query['$project'] = project.toString();
  }
  if (options) {
    if (options.limit) {
      query['$limit'] = options.limit;
    }
    if (options.page) {
      query['$page'] = options.page;
    }
    if (options.search) {
      query['$text'] = options.search;
    }
    if (options.populate) {
      query['$populate'] = options.populate;
    }
    if (options.sort) {
      const $sort = [];
      for (const key in options.sort) {
        if (Object.prototype.hasOwnProperty.call(options.sort, key)) {
          const order = options.sort[key];
          if (order == 'asc') {
            $sort.push(`${key}=1`);
          } else if (order == 'des') {
            $sort.push(`${key}=-1`);
          }
        }
      }
      query['$sort'] = $sort.toString();
    }
  }
  let queryParams = objectToQueryString(query);
  if (filter && typeof filter == 'object' && Object.keys(filter)) {
    queryParams += '&' + objectToQueryString(filter);
  }
  return queryParams;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function objectToQueryString(initialObj: Object) {
  const reducer =
    (obj:any, parentPrefix = null) =>
    (prev:any, key:any) => {
      const val = obj[key];

      // key = key;

      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        // prev.push(`${prefix}=`);
        return prev;
      }

      if (['number', 'boolean', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${val}`);
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };
  return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
}
