import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './styles/App.css';
import './styles/globals.scss';
import { Login } from './Pages/Login';
import { MainLayout } from './Components/MainLayout';
import { Content } from '@carbon/react';
import { ProtectedRoute } from './Components/ProtectedRoute/PrivateRoute';
import { DriveFiles } from './Pages/DriveFiles';

function App() {
  const location = useLocation();

  const [isLoginPage, setIsLoginPage] = useState(true);
  useEffect(() => {
    if (location.pathname === '/login') {
      setIsLoginPage(true);
    } else {
      setIsLoginPage(false);
    }
  }, [location]);

  return (
    <div className='App'>
      <Content className={isLoginPage ? 'cds--padding-0' : 'main-content'}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/*' element={<MainLayout />} />
        </Routes>
      </Content>
    </div>
  );
}

export default App;
